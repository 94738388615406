/* body {
    position: relative;
    height: 100vh;
    margin: 0;
    background: darkslategrey;
  } */
  .chicken-logo{
    position:relative;
  }

  .chicken {
    position: relative;
    
    margin-top: 150px;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 100px;
    transform: translate(-50%, -50%);
  }
  
  .body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    background: bisque;
    z-index: 2;
  }
  
  .body::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    width: 20%;
    height: 40%;
    border-bottom-right-radius: 1000px;
    border-top: 1px solid darkslategray;
    border-bottom: 1px solid darkslategray;
    border-right: 1px solid darkslategray;
    animation: flap 1s infinite ease-out;
    transform-origin: 0 0;
  }
  
  .head {
    position: absolute;
    top: -50px;
    left: 0;
    width: 50px;
    height: 50px;
    background: bisque;
    border-top-right-radius: 23123px;
  }
  
  .head::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 30%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: black;
    transform: translate(-50%, -50%);
    animation: blink 5s infinite;
  }
  
  .beak {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: darkorange;
  }
  
  .tail {
    position: absolute;
    top: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    background: bisque;
    border-top-left-radius: 123132px;
  }
  
  .leg.one {
    left: 40%;
  }
  
  .leg.two {
    left: 60%;
  }
  
  .leg {
    position: absolute;
    bottom: -18px;
    width: 2px;
    height: 20px;
    background: darkorange;
  }
  
  .leg::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 5px;
    height: 2px;
    background: darkorange;
  }
  
  .hair {
    position: absolute;
    top: -5px;
    left: -1px;
    width: 5px;
    height: 10px;
    background: darkorange;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    transform: rotate(-10deg);
  }
  
  .hair::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 5px;
    height: 8px;
    background: darkorange;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  
  .hair::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 10px;
    width: 5px;
    height: 6px;
    background: darkorange;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  
  @keyframes flap {
    0%,
    100% {
      transform: rotateY(0) rotateZ(0);
    }
  
    50% {
      transform: rotateY(-40deg) rotateZ(-10deg);
    }
  }
  
  @keyframes blink {
    0%,
    48%,
    52%,
    100% {
      height: 10px;
    }
  
    50% {
      height: 1px;
    }
  }



  .egg- {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #808000;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-Batty {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #35431b;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }
  
  .egg-Branch {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #fff1f1;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-ChickySpears {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #469a73;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-GirlDiamond {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #fff1f1;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-GoldieLocks {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #9c8200;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-Lacy {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #ecc08b;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-Lav {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #ecc08b;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-LittleFoot {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #8df9fb;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-Roadie {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #ffffff;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }

  .egg-Robin {
    width: 50px;
    height: 70px;
    border-radius: 50% 50% 60% 60%;
    background-color: #ecc08b;
    position: relative;
    animation: drop-egg 1s ease-in-out;
    left:75%;
    transform: translateX(-25%); 
    z-index: 1;
  }
  
  @keyframes drop-egg {
    0% {
      top: 0;
    }
    30% {
      top: 50px;
      transform: rotate(30deg);
    }
    60% {
      top: 100px;
      transform: rotate(60deg);
    }
    100% {
      top: 200px;
      transform: rotate(90deg);
    }
  }