/* Chicks Age Container */

.chick-age-row{
    margin-top: 6rem;
  }
.chick-age-col{
    display: flex;
    justify-content: center;
}
  
  .chick-age-card{
    width: 18rem;
    min-height: 26rem;
    justify-self: center;
    margin-top: 2rem;
  }
  
  .card-title {
    color: black;
    
  }
  
  .card-text{
    color: black;
    font-size: 1em;
    display: flex;
    justify-content: center;
  }
  
  .card-body{
    max-height: 2rem;
  }