/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* History */
.history-header{
  margin-top: 2rem;
}

.history-individual-row-date{
  justify-content: center;
}

.history-individual-row-count{
  justify-content: center;
}
.history-individual-column{
  border-right: 2px solid white;
}

.history-row div:last-child{
  border: none;
}

.year-to-date-history-container{
  margin: 3rem;

}

.year-to-date-total-egg-count{
  margin-bottom: 3rem;
}

.chicks-age-in-weeks{
  padding-top:4%;
}
.daily-average{
  padding-top: 1em;
  font-size: 1em;
  text-align: center;
}

.modal-content
{
    --bs-modal-bg: transparent;
    border: none;
}


